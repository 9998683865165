import React from 'react'
import Layout from '../layouts'
import './index.css'
import logo1 from '../images/realtor.png'
import logo2 from '../images/buffini.png'
import logo3 from '../images/drs.png'
import { Link } from 'gatsby'
import buyingBG from '../images/buying-bg.jpg'
import first from '../images/first-buyer.jpg'

const Relocation = () => (
  <Layout head={{ title: 'Relocation from California or Texas' }}>
    <div className="page-header">
      <div
        className="page-banner buying"
        style={{
          width: `100%`,
          height: `100%`,
          background: `url(${buyingBG})`,
          top: '12%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-xl-6">
            <div className="hero-content align-self-center">
              <h1 className="text-white align-self-center">
                Relocation from California or Texas
              </h1>
              <p className="text-white">
                Give Brian a call at{' '}
                <a href="tel:7194599955" className="text-white">
                  (719) 459-9955
                </a>{' '}
                or send us an email at{' '}
                <a href="mailto:brianboals@gmail.com" className="text-white">
                  brianboals@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container px-5 mx-auto relocationCalifornia-section mt-20">
      <div className="row align-items-center box-shadow">
        <div className="col-12 col-lg-6 mb-5 mb-lg-0">
          <div className="left-info">
            <p className="mb-4 mt-20">
              If you're tired of high taxes and ready for gorgeous scenery with
              America's Mountain lead the Brian Boals team offer you a one of a
              kind driving tour of Colorado Springs seeing if perhaps Colorado
              Springs is the right fit for you. On this free tour from a born
              natives perspective, you will see a variety of homes and prices,
              recreation, parks, amenities, the best restaurants, key
              businesses, schools and special insights about what makes Colorado
              Springs one of the finest cities in America to live.
            </p>
            <p className="mb-4">
              Call Brian today to set up this free tour anytime Monday through
              Friday. You won't be disappointed! Get ready to move and call
              Colorado Springs your home! By the way, we are never too busy for
              any of YOUR referrals. We would be happy to give any of your
              friends and family, the same tour and red carpet treatment.
            </p>
            <div className="position-relative mb-3">
              <a
                href="tel:7194599955"
                className="alink hvr-shadow my-1 text-center primary-button btn-before border-none"
              >
                Call Us at (719) 459-9955
              </a>
            </div>
            <a
              href="mailto:brianboals@gmail.com"
              className="alink hvr-shadow text-center secondary-button btn-before"
              role="button"
            >
              Email Us at brianboals@gmail.com
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-6 h-100">
          <div className="bg-image">
            <div className="back-bg" />
            <div
              className="img"
              style={{
                backgroundImage: `url('https://res.cloudinary.com/brian-boals/image/upload/v1556763394/brianboals/citybg.jpg')`,
                backgroundColor: '#012d6f',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default Relocation
